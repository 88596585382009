import React, { useEffect, useState } from 'react';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { E164Number } from 'libphonenumber-js';
import { Box } from 'src/components/Box';
import CircularProgress from 'src/components/core/CircularProgress';
import { Button } from '@bitnimbus/ui-components';
import { Typography } from 'src/components/Typography';
import { makeStyles } from '@mui/styles';
import Edit from '@mui/icons-material/Edit';
import { APIError } from '@bitnimbus/api-v4';

const useStyles = makeStyles((theme) => ({
  phoneInput: {
    '& input': {
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: '8px',
      padding: '4px 8px',
      color: theme.palette.text.primary,
    },
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  optSentText: {
    margin: `${theme.spacing(3)} 0`,
    textAlign: 'center',
  },
  editButton: {
    margin: '2px 0',
    padding: '0 8px',
    '& svg': {
      height: '1.2rem',
    },
  },
  errorText: {
    color: theme.palette.error.dark,
  },
}));

interface SendOtpProps {
  sendOtp: (phone: string) => Promise<void>;
  sendOtpLoading?: boolean;
  sendOtpError?: APIError[] | null;
  sendOtpSuccess?: boolean;
  phone: string;
  toggleUpdate: (enabled: boolean) => void;
  updateEnabled?: boolean;
}

const SendOtp = (props: SendOtpProps) => {
  const classes = useStyles();

  const {
    sendOtp,
    sendOtpLoading,
    sendOtpError,
    sendOtpSuccess,
    toggleUpdate,
    phone,
  } = props;
  const [state, setState] = useState<string>();

  const handleChange = (value: E164Number | undefined) => {
    setState(value);
  };

  const handleUpdate = async () => {
    await sendOtp(state || '');
  };

  const handleCancel = () => {
    toggleUpdate(false);
  };

  const enableUpdate = () => {
    setState(phone);
    toggleUpdate(true);
  };

  useEffect(() => {
    if (sendOtpSuccess) {
      toggleUpdate(false);
    }
  }, [sendOtpSuccess]);

  useEffect(() => {
    if (phone) {
      setState(phone);
    }
  }, [phone]);

  return (
    <Box marginBottom={4}>
      {props.updateEnabled ? (
        <div className={classes.phoneInput}>
          <PhoneInput
            style={{
              marginRight: '1rem',
            }}
            fontSize="1rem"
            placeholder="Enter Mobile Number"
            value={state}
            countries={['IN', 'US']}
            defaultCountry="US"
            addInternationalOption={false}
            onChange={handleChange}
            numberInputProps={{
              ['data-testid']: 'mobile-verification__phone-input',
            }}
            countrySelectProps={{
              ['data-testid']: 'mobile-verification__country-select',
            }}
          />
          {sendOtpLoading ? (
            <div style={{ alignSelf: 'center' }}>
              <CircularProgress size={24} />
            </div>
          ) : (
            <Button
              variant="link"
              style={{ padding: '4px' }}
              disabled={!state}
              onClick={handleUpdate}
              {...{
                ['data-testid']: 'mobile-verification__send-button',
              }}
            >
              <Check />
            </Button>
          )}
          <Button
            variant="link"
            style={{ padding: '4px' }}
            disabled={!phone || sendOtpLoading}
            onClick={handleCancel}
            {...{
              ['data-testid']: 'send-otp__cancel-button',
            }}
          >
            <Close />
          </Button>
        </div>
      ) : (
        <Typography className={classes.optSentText}>
          A 4-digit OTP has been sent to <strong>{phone}</strong>
          <Button
            variant="link"
            className={classes.editButton}
            onClick={enableUpdate}
          >
            <Edit />
          </Button>
        </Typography>
      )}
      {sendOtpError && (
        <Typography className={classes.errorText}>
          {sendOtpError[0].reason}
        </Typography>
      )}
    </Box>
  );
};

export default SendOtp;
