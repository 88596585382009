import React, { useEffect, useState } from 'react';

import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';
import Edit from '@mui/icons-material/Edit';

import { Box } from 'src/components/Box';
import PhoneInput from 'react-phone-number-input';
import { Typography } from 'src/components/Typography';
import { Button, InputOTP } from '@bitnimbus/ui-components';

import 'react-phone-number-input/style.css';
import { E164Number } from 'libphonenumber-js';
import { Divider } from '@mui/material';
import CircularProgress from 'src/components/core/CircularProgress';
import { makeStyles } from '@mui/styles';
import {
  useSendPhoneVerificationCodeMutation,
  useVerifyPhoneVerificationCodeMutation,
} from 'src/queries/profile';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/store/globalErrors/globalErrors.actions';
import SendOtp from './SendOtp';
import VerifyOtp from './VerifyOtp';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.5rem',
    fontWeight: 500,
    textAlign: 'center',
  },
  phoneInput: {
    '& input': {
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: '8px',
      padding: '4px 8px',
      color: theme.palette.text.primary,
    },
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  otpTitle: {
    fontSize: '1.2rem',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  verifyButton: {
    marginTop: theme.spacing(2),
  },
  resendText: {
    marginTop: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  optSentText: {
    margin: `${theme.spacing(3)} 0`,
    textAlign: 'center',
  },
  editButton: {
    margin: '2px 0',
    padding: '0 8px',
    '& svg': {
      height: '1.2rem',
    },
  },
  errorText: {
    color: theme.palette.error.dark,
  },
}));

const MobileVerification = () => {
  const classes = useStyles();
  const [phone, setPhone] = useState<string>();
  const [updateEnabled, setUpdateEnabled] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    mutateAsync: sendOtp,
    isLoading: sendOtpLoading,
    error: sendOtpError,
    isSuccess: sendOtpSuccess,
  } = useSendPhoneVerificationCodeMutation();

  const {
    mutateAsync: verifyOtp,
    isLoading: verifyOtpLoading,
    error: verifyOtpError,
    isSuccess: verifiyOtpSuccess,
  } = useVerifyPhoneVerificationCodeMutation();

  useEffect(() => {
    if (verifiyOtpSuccess) {
      enqueueSnackbar('You have successfully verified your mobile number', {
        variant: 'success',
      });
      dispatch(
        setErrors({
          MOBILE_NOT_VERIFIED: false,
        })
      );
    }
  }, [verifiyOtpSuccess]);

  const handleVerifyOtp = async (otp: string) => {
    try {
      await verifyOtp({
        code: otp || '',
      });
    } catch (err) {}
  };

  const handleUpdate = async (value: string) => {
    await sendOtp({
      phone: value,
    });
    setPhone(value);
    enqueueSnackbar('OTP has been sent to your mobile', {
      variant: 'success',
    });
  };

  const handleResend = async () => {
    await sendOtp({
      phone: phone || '',
    });
    enqueueSnackbar('OTP has been sent to your mobile', {
      variant: 'success',
    });
  };

  return (
    <Box maxWidth="100%">
      <Box marginBottom="1rem" display="flex" justifyContent="center">
        <img
          src="https://bitnimbus-public-assets.us-east-1.linodeobjects.com/static/images/Bitnimbus.io_purple_logo.png"
          alt="Bitnimbus.io"
          style={{ height: '2.4rem', width: 'auto' }}
        />
      </Box>
      <Typography variant="h1" className={classes.title}>
        Mobile Verification
      </Typography>
      <Box marginTop="1rem" textAlign="center">
        <Typography>
          Verify your Mobile number to continue our services
        </Typography>
        <SendOtp
          phone={phone || ''}
          updateEnabled={updateEnabled}
          sendOtp={handleUpdate}
          sendOtpLoading={sendOtpLoading}
          sendOtpError={sendOtpError}
          sendOtpSuccess={sendOtpSuccess}
          toggleUpdate={(enabled) => setUpdateEnabled(enabled)}
        />
        <Divider />
        <Box marginTop={4} display="flex" justifyContent="center">
          <VerifyOtp
            verifyOtp={handleVerifyOtp}
            verifyOtpError={verifyOtpError}
            verifyOtpLoading={verifyOtpLoading}
            verifyOtpSuccess={verifiyOtpSuccess}
            resendOtp={handleResend}
            disabled={!phone || updateEnabled || sendOtpLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MobileVerification;
