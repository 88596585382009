import { isEmpty } from 'ramda';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import Logo from 'src/assets/logo/bitnimbus-logo.png';
import { Box } from 'src/components/Box';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import MainContentBanner from 'src/components/MainContentBanner';
import { MaintenanceScreen } from 'src/components/MaintenanceScreen';
import NotFound from 'src/components/NotFound';
import { PreferenceToggle } from 'src/components/PreferenceToggle/PreferenceToggle';
import SideMenu from 'src/components/SideMenu';
import SuspenseLoader from 'src/components/SuspenseLoader';
import withGlobalErrors, {
  Props as GlobalErrorProps,
} from 'src/containers/globalErrors.container';
import { useDialogContext } from 'src/context';
import GlobalNotifications from 'src/features/GlobalNotifications';
import {
  notificationContext,
  useNotificationContext,
} from 'src/features/NotificationCenter/NotificationContext';
import ToastNotifications from 'src/features/ToastNotifications';
import TopMenu from 'src/features/TopMenu';
import useAccountManagement from 'src/hooks/useAccountManagement';
import useFlags from 'src/hooks/useFlags';
import { usePreferences } from 'src/queries/preferences';
import { complianceUpdateContext } from './context/complianceUpdateContext';
import { FlagSet } from './featureFlags';
import { ManagerPreferences } from 'src/types/ManagerPreferences';
import { ENABLE_MAINTENANCE_MODE } from './constants';
import type { PreferenceToggleProps } from 'src/components/PreferenceToggle/PreferenceToggle';
import VectordbRoutes from 'src/features/vectordb';
import ProductLanding from './features/ProductLanding';
import MobileVerification from './features/Account/MobileVerification';
import { Card } from '@bitnimbus/ui-components';

const useStyles = makeStyles()((theme: Theme) => ({
  appFrame: {
    position: 'relative',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: theme.bg.app,
    zIndex: 1,
  },
  cmrWrapper: {
    maxWidth: `${theme.breakpoints.values.lg}px !important`,
    padding: `${theme.spacing(3)} 0`,
    paddingTop: 12,
    transition: theme.transitions.create('opacity'),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.between('md', 'xl')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  content: {
    flex: 1,
    transition: 'margin-left .1s linear',
    [theme.breakpoints.up('md')]: {
      marginLeft: 190,
    },
  },
  fullWidthContent: {
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: 52,
    },
  },
  hidden: {
    display: 'none',
    overflow: 'hidden',
  },
  grid: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      height: '100%',
    },
  },
  switchWrapper: {
    flex: 1,
    maxWidth: '100%',
    position: 'relative',
    '& > .MuiGrid-container': {
      maxWidth: theme.breakpoints.values.lg,
      width: '100%',
    },
    '&.mlMain': {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '78.8%',
      },
    },
    '& .mlSidebar': {
      [theme.breakpoints.up('lg')]: {
        paddingRight: `0 !important`,
      },
    },
  },
  logo: {
    '& > g': {
      fill: theme.color.black,
    },
  },
  activationWrapper: {
    padding: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      width: '50%',
      margin: '0 auto',
    },
  },
  bgStyling: {
    backgroundColor: theme.bg.main,
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

interface Props {
  appIsLoading: boolean;
  isLoggedInAsCustomer: boolean;
}

type CombinedProps = Props & GlobalErrorProps;

const Account = React.lazy(() => import('src/features/Account'));
const KafkaRoutes = React.lazy(() => import('src/features/Kafka'));
const Profile = React.lazy(() => import('src/features/Profile'));
const Vectordb = React.lazy(() => import('src/features/vectordb'));

const MainContent = (props: CombinedProps) => {
  const { classes, cx } = useStyles();
  const flags = useFlags();
  const { data: preferences } = usePreferences();

  const NotificationProvider = notificationContext.Provider;
  const contextValue = useNotificationContext();

  const ComplianceUpdateProvider = complianceUpdateContext.Provider;
  const complianceUpdateContextValue = useDialogContext();

  const [menuIsOpen, toggleMenu] = React.useState<boolean>(false);
  const { profile, _isManagedAccount } = useAccountManagement();

  const username = profile?.username || '';

  const [bannerDismissed, setBannerDismissed] = React.useState<boolean>(false);

  const defaultRoot = _isManagedAccount ? '/managed' : '/kafka';

  const shouldDisplayMainContentBanner =
    !bannerDismissed &&
    checkFlagsForMainContentBanner(flags) &&
    !checkPreferencesForBannerDismissal(
      preferences ?? {},
      flags?.mainContentBanner?.key
    );
  if (props.globalErrors.MOBILE_NOT_VERIFIED) {
    return (
      <div className={classes.bgStyling}>
        <Box width="36vw" margin="auto">
          <Card
            content={
              <Box padding="0 1rem">
                <MobileVerification />
              </Box>
            }
          />
        </Box>
      </div>
    );
  }

  // If the API is in maintenance mode, return a Maintenance screen
  if (props.globalErrors.api_maintenance_mode || ENABLE_MAINTENANCE_MODE) {
    return <MaintenanceScreen />;
  }

  /**
   * otherwise just show the rest of the app.
   */
  return (
    <div
      className={cx({
        [classes.appFrame]: true,
        /**
         * hidden to prevent some jankiness with the app loading before the splash screen
         */
        [classes.hidden]: props.appIsLoading,
      })}
    >
      <PreferenceToggle<boolean>
        preferenceKey="desktop_sidebar_open"
        preferenceOptions={[true, false]}
      >
        {({
          preference: desktopMenuIsOpen,
          togglePreference: desktopMenuToggle,
        }: PreferenceToggleProps<boolean>) => (
          <ComplianceUpdateProvider value={complianceUpdateContextValue}>
            <NotificationProvider value={contextValue}>
              <>
                {shouldDisplayMainContentBanner ? (
                  <MainContentBanner
                    bannerText={flags.mainContentBanner?.text ?? ''}
                    url={flags.mainContentBanner?.link?.url ?? ''}
                    linkText={flags.mainContentBanner?.link?.text ?? ''}
                    bannerKey={flags.mainContentBanner?.key ?? ''}
                    onClose={() => setBannerDismissed(true)}
                  />
                ) : null}
                <SideMenu
                  open={menuIsOpen}
                  collapse={desktopMenuIsOpen || false}
                  closeMenu={() => toggleMenu(false)}
                />
                <div
                  className={cx(classes.content, {
                    [classes.fullWidthContent]:
                      desktopMenuIsOpen ||
                      (desktopMenuIsOpen && desktopMenuIsOpen === true),
                  })}
                >
                  <TopMenu
                    isSideMenuOpen={!desktopMenuIsOpen}
                    openSideMenu={() => toggleMenu(true)}
                    desktopMenuToggle={desktopMenuToggle}
                    isLoggedInAsCustomer={props.isLoggedInAsCustomer}
                    username={username}
                  />
                  <main
                    className={classes.cmrWrapper}
                    id="main-content"
                    role="main"
                  >
                    <Grid container spacing={0} className={classes.grid}>
                      <Grid className={cx(classes.switchWrapper, 'p0')}>
                        <GlobalNotifications />
                        <React.Suspense fallback={<SuspenseLoader />}>
                          <Switch>
                            <Route path="/kafka" component={KafkaRoutes} />
                            <Route path="/account" component={Account} />
                            <Route
                              path="/vectordb"
                              component={VectordbRoutes}
                            />
                            <Route
                              path="/profile"
                              render={(routeProps) => (
                                <Profile {...routeProps} />
                              )}
                            />
                            {/*<Route path="/search" component={SearchLanding} />*/}
                            {/* <Redirect exact from="/" to={defaultRoot} /> */}
                            <Route exact path="/" component={ProductLanding} />
                            {/** We don't want to break any bookmarks. This can probably be removed eventually. */}
                            <Redirect from="/dashboard" to={defaultRoot} />
                            <Route component={NotFound} />
                          </Switch>
                        </React.Suspense>
                      </Grid>
                    </Grid>
                  </main>
                </div>
              </>
            </NotificationProvider>
            {/*<Footer desktopMenuIsOpen={desktopMenuIsOpen} />*/}
            <ToastNotifications />
          </ComplianceUpdateProvider>
        )}
      </PreferenceToggle>
    </div>
  );
};

export default compose<CombinedProps, Props>(
  React.memo,
  withGlobalErrors()
)(MainContent);

// =============================================================================
// Utilities
// =============================================================================
export const checkFlagsForMainContentBanner = (flags: FlagSet) => {
  return Boolean(
    flags.mainContentBanner &&
      !isEmpty(flags.mainContentBanner) &&
      flags.mainContentBanner.key
  );
};

export const checkPreferencesForBannerDismissal = (
  preferences: ManagerPreferences,
  key = 'defaultKey'
) => {
  return Boolean(preferences?.main_content_banner_dismissal?.[key]);
};
