var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};

// src/account.schema.ts
import { array, boolean, mixed, number, object, string } from "yup";
var updateAccountSchema = object({
  email: string().trim().required("Required").max(128, "Email must be 128 characters or less."),
  first_name: string().trim().required("Required").max(50).matches(/^[a-zA-Z]+$/, {
    excludeEmptyString: true,
    message: "Should have only letters"
  }),
  last_name: string().trim().required("Required").max(50).matches(/^[a-zA-Z]+$/, {
    excludeEmptyString: true,
    message: "Should have only letters"
  }),
  company_name: string().trim().optional().nullable().max(150).matches(/^[a-zA-Z\.-\s]+$/, {
    excludeEmptyString: true,
    message: "Should have only letters, dot and space"
  }),
  address_line_1: string().trim().required("Required").min(5, "Shoud be minimum of 5 characters").max(150).matches(/^[a-zA-Z0-9,\-\s\/]+$/, {
    excludeEmptyString: true,
    message: "Can have letters, numbers, comma, space, hyphen and slash"
  }),
  address_line_2: string().trim().optional().max(150).matches(/^[a-zA-Z0-9,\-\s\/]+$/, {
    excludeEmptyString: true,
    message: "Can have letters, numbers, comma, space, hyphen and slash"
  }),
  city: string().trim().required("Required").max(150, "Should be maximum of 150 characters").matches(/^[a-zA-Z\s]+$/, {
    excludeEmptyString: true,
    message: "Should have only letters"
  }),
  country: string().trim().required("Required").max(150, "Should be maximum of 150 characters"),
  state: string().trim().required("Required").max(150, "Should be maximum of 150 characters").matches(/^[a-zA-Z\s]+$/, {
    excludeEmptyString: true,
    message: "Should have only letters and space"
  }),
  zipcode: string().trim().required("Required").max(20, "Should be maximum of 20 characters").matches(/^[a-zA-Z0-9]+$/, {
    excludeEmptyString: true,
    message: "Should have only letters and digits"
  })
});
var createOAuthClientSchema = object({
  label: string().required("Required.").min(1, "Label must be between 1 and 512 characters.").max(512, "Label must be between 1 and 512 characters."),
  redirect_uri: string().required("Redirect Required.")
});
var updateOAuthClientSchema = object({
  label: string().min(1, "Label must be between 1 and 512 characters.").max(512, "Label must be between 1 and 512 characters."),
  redirect_uri: string()
});
var PaymentSchema = object({
  usd: string().required("Required.")
});
var CreditCardSchema = object({
  card_number: string().required("Credit card Required.").min(13, "Credit card number must be between 13 and 23 characters.").max(23, "Credit card number must be between 13 and 23 characters."),
  expiry_year: number().test(
    "length",
    "Expiration year must be 2 or 4 digits.",
    (value) => [2, 4].includes(String(value).length)
  ).required("Expiration Required.").typeError("Expiration year must be a number.").min((/* @__PURE__ */ new Date()).getFullYear(), "Expiration year must not be in the past.").max((/* @__PURE__ */ new Date()).getFullYear() + 20, "Expiry too far in the future."),
  expiry_month: number().required("Expiration Required.").typeError("Expiration month must be a number.").min(1, "Expiration month must be a number from 1 to 12.").max(12, "Expiration month must be a number from 1 to 12."),
  cvv: string().required("Security Required.").min(3, "Security code must be between 3 and 4 characters.").max(4, "Security code must be between 3 and 4 characters.")
});
var PaymentMethodSchema = object({
  type: mixed().oneOf(
    ["credit_card", "payment_method_nonce"],
    "Type must be credit_card or payment_method_nonce."
  ),
  data: object().when("type", {
    is: "credit_card",
    then: CreditCardSchema,
    otherwise: object({
      nonce: string().required("Payment Required.")
    })
  }),
  is_default: boolean().required(
    "You must indicate if this should be your default method of payment."
  )
});
var CreateUserSchema = object({
  username: string().required("Required.").min(5, "Username must be between 5 and 50 characters.").max(50, "Username must be between 5 and 50 characters.").matches(/^[a-zA-Z][\w_]+$/, {
    excludeEmptyString: true,
    message: "Username does not meet minimum username requirement"
  }),
  email: string().required("Required.").email("Invalid Email Address"),
  restricted: boolean().required(
    "You must indicate if this user should have restricted access."
  )
});
var UpdateUserSchema = object({
  username: string().min(3, "Username must be between 3 and 32 characters.").max(32, "Username must be between 3 and 32 characters."),
  email: string().email("Must be a valid email address."),
  restricted: boolean()
});
var GrantSchema = object({
  id: number().required("Required."),
  permissions: mixed().oneOf(
    [null, "read_only", "read_write"],
    "Permissions must be null, read_only, or read_write."
  )
});
var UpdateGrantSchema = object({
  global: object(),
  linode: array().of(GrantSchema),
  domain: array().of(GrantSchema),
  nodebalancer: array().of(GrantSchema),
  image: array().of(GrantSchema),
  longview: array().of(GrantSchema),
  stackscript: array().of(GrantSchema),
  volume: array().of(GrantSchema)
});
var UpdateAccountSettingsSchema = object({
  network_helper: boolean(),
  backups_enabled: boolean(),
  managed: boolean()
});
var PromoCodeSchema = object({
  promo_code: string().required("Promo Required.").min(1, "Promo code must be between 1 and 32 characters.").max(32, "Promo code must be between 1 and 32 characters.")
});

// src/kafka.schema.ts
import { array as array2, mixed as mixed2, object as object2, string as string2 } from "yup";
import ipAddr from "ipaddr.js";
var isNetworkAddress = (network, ip) => {
  const ipStr = ip.split("/")[0];
  if (network !== ipStr) {
    return false;
  }
  return true;
};
var validateIP = (ip) => {
  const NETWORKS_TO_EXCLUDE = ["private", "loopback"];
  if (ip.split(".").length !== 4) {
    return "Should be a valid IP Address";
  }
  let ipRange = null;
  if (ipAddr.isValidCIDR(ip)) {
    const networkObj = ipAddr.IPv4.networkAddressFromCIDR(ip);
    if (!isNetworkAddress(networkObj.toString(), ip)) {
      return "Network Address has host bits set";
    }
    ipRange = networkObj.range();
  } else if (ipAddr.isValid(ip)) {
    const ipObj = ipAddr.parse(ip);
    ipRange = ipObj.range();
  } else {
    return "Should be a valid IP Address or CIDR";
  }
  if (NETWORKS_TO_EXCLUDE.includes(ipRange)) {
    return "Should be a public IP Address or Network";
  }
  return null;
};
var validateIPList = (ipList, createError) => {
  if (!ipList) {
    return true;
  }
  if (ipList && ipList.length === 0) {
    return true;
  }
  const errObj = {};
  for (const ip of ipList) {
    if (!ip) {
      return createError({ "": "Please provide valid IP Address" });
    }
    const err = validateIP(ip);
    if (err) {
      errObj[ip] = err;
    }
  }
  if (Object.keys(errObj).length > 0) {
    return createError({
      params: { input: __spreadValues({}, errObj) },
      message: "Please hover over the highlighted IP addresses to get the error message"
    });
  }
  return true;
};
var CreateKafkaSchema = object2({
  cloud_provider: string2().ensure().required("Please Choose a Provider to deploy your Cluster to."),
  plan: string2().ensure().required("Choose a plan."),
  region: string2().ensure().required("Required"),
  label: string2().transform((v) => v === "" ? void 0 : v).notRequired().trim().min(3, "Label must contain between 3 and 64 characters.").max(64, "Label must contain between 3 and 64 characters."),
  acl_ips: array2().of(string2()).test("is-valid-ipset", "Provide valid ip address list", function(ipList) {
    const { createError } = this;
    return validateIPList(ipList, createError);
  })
});
var DeleteKafkaSchema = object2({
  id: string2().ensure().required("Please Provide the Cluster ID which you want to delete.")
});
var UpdateKafkaSchema = object2({
  label: string2().transform((v) => v === "" ? void 0 : v).notRequired().min(3, "Label must contain between 3 and 64 characters.").max(64, "Label must contain between 3 and 64 characters."),
  acl_ips: array2().of(string2()).test(
    "validate-ip-list",
    "Provided IP List is not valid",
    function(ipList) {
      if (!ipList || ipList.length === 0) {
        return true;
      }
      const { createError } = this;
      return validateIPList(ipList, createError);
    }
  ),
  cloud_provider: mixed2().oneOf(["linode", "hetzner"])
});

// src/profile.schema.ts
import { array as array3, boolean as boolean2, number as number2, object as object3, string as string3 } from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";
var createPersonalAccessTokenSchema = object3({
  scopes: string3(),
  expiry: string3(),
  label: string3().min(1, "Label must be between 1 and 100 characters.").max(100, "Label must be between 1 and 100 characters.")
});
var createSSHKeySchema = object3({
  label: string3().required("Label is required.").min(1, "Label must be between 1 and 64 characters.").max(64, "Label must be between 1 and 64 characters.").trim(),
  ssh_key: string3()
});
var updateSSHKeySchema = object3({
  label: string3().required("Label is required.").min(1, "Label must be between 1 and 64 characters.").max(64, "Label must be between 1 and 64 characters.").trim()
});
var updateProfileSchema = object3({
  email: string3().email(),
  timezone: string3(),
  email_notifications: boolean2(),
  authorized_keys: array3().of(string3()),
  restricted: boolean2(),
  two_factor_auth: boolean2(),
  authentication_type: string3().oneOf(["password", "github"]),
  username: string3().min(5, "Username must be between 5 and 50 characters.").max(50, "Username must be between 5 and 50 characters.").matches(/^[a-zA-Z][\w_]+$/, {
    excludeEmptyString: true,
    message: "Username does not meet minimum username requirement"
  })
});
var SendCodeToPhoneNumberSchema = object3({
  phone: string3().test(
    "is-phone-number",
    "Not a valid phone number",
    (phone) => {
      if (!phone) {
        return false;
      }
      return isValidPhoneNumber(phone);
    }
  )
});
var VerifyPhoneNumberCodeSchema = object3({
  code: string3().required("Verification Code is required.").test(
    "digits only",
    "The verification code must only contain digits.",
    (value) => {
      if (!value) {
        return true;
      }
      return /^\d+$/.test(value);
    }
  )
});
var SecurityQuestionsSchema = object3({
  security_questions: array3().of(
    object3({
      question_id: number2().required("You must pick a question."),
      response: string3().min(3, "Answers must be at least 3 characters.").max(17, "Answers must be at most 17 characters.").required("You must provide an answer to each security question.")
    }).required()
  ).length(3, "You must answer all 3 security questions.").required()
});

// src/twofactor.schema.ts
import { object as object4, string as string4 } from "yup";
var enableTwoFactorSchema = object4({
  tfa_code: string4().required("Please enter a token.")
});

// src/feedback.schema.ts
import { object as object5, string as string5 } from "yup";
var FeedbackSchema = object5({
  comments: string5().trim().optional().max(500, "Should not exceed more than 500 characters")
});

// src/resource.schema.ts
import ipAddr2 from "ipaddr.js";
import { array as array4, object as object6, string as string6 } from "yup";
var isNetworkAddress2 = (network, ip) => {
  const ipStr = ip.split("/")[0];
  if (network !== ipStr) {
    return false;
  }
  return true;
};
var validateIP2 = (ip) => {
  const NETWORKS_TO_EXCLUDE = ["private", "loopback"];
  if (ip.split(".").length !== 4) {
    return "Should be a valid IP Address";
  }
  let ipRange = null;
  if (ipAddr2.isValidCIDR(ip)) {
    const networkObj = ipAddr2.IPv4.networkAddressFromCIDR(ip);
    if (!isNetworkAddress2(networkObj.toString(), ip)) {
      return "Network Address has host bits set";
    }
    ipRange = networkObj.range();
  } else if (ipAddr2.isValid(ip)) {
    const ipObj = ipAddr2.parse(ip);
    ipRange = ipObj.range();
  } else {
    return "Should be a valid IP Address or CIDR";
  }
  if (NETWORKS_TO_EXCLUDE.includes(ipRange)) {
    return "Should be a public IP Address or Network";
  }
  return null;
};
var validateIPList2 = (ipList, createError) => {
  if (!ipList) {
    return true;
  }
  if (ipList && ipList.length === 0) {
    return true;
  }
  const errObj = {};
  for (const ip of ipList) {
    if (!ip) {
      return createError({ "": "Please provide valid IP Address" });
    }
    const err = validateIP2(ip);
    if (err) {
      errObj[ip] = err;
    }
  }
  if (Object.keys(errObj).length > 0) {
    return createError({
      params: { input: __spreadValues({}, errObj) },
      message: "Please hover over the highlighted IP addresses to get the error message"
    });
  }
  return true;
};
var CreateResourceSchema = object6({
  cloud_provider: string6().ensure().required("Please Choose a Provider to deploy your Cluster to."),
  size: string6().ensure().required("Choose a plan."),
  region: string6().ensure().required("Required"),
  label: string6().transform((v) => v === "" ? void 0 : v).trim().required("Required").min(3, "Label must contain between 3 and 64 characters.").max(64, "Label must contain between 3 and 64 characters."),
  acl_ips: array4().of(string6()).test("is-valid-ipset", "Provide valid ip address list", function(ipList) {
    const { createError } = this;
    return validateIPList2(ipList, createError);
  })
});
var UpdateResourceSchema = object6({
  label: string6().transform((v) => v === "" ? void 0 : v).trim().min(3, "Label must contain between 3 and 64 characters.").max(64, "Label must contain between 3 and 64 characters."),
  acl_ips: array4().of(string6()).test("is-valid-ipset", "Provide valid ip address list", function(ipList) {
    const { createError } = this;
    return validateIPList2(ipList, createError);
  })
});
export {
  CreateKafkaSchema,
  CreateResourceSchema,
  CreateUserSchema,
  CreditCardSchema,
  DeleteKafkaSchema,
  FeedbackSchema,
  PaymentMethodSchema,
  PaymentSchema,
  PromoCodeSchema,
  SecurityQuestionsSchema,
  SendCodeToPhoneNumberSchema,
  UpdateAccountSettingsSchema,
  UpdateGrantSchema,
  UpdateKafkaSchema,
  UpdateResourceSchema,
  UpdateUserSchema,
  VerifyPhoneNumberCodeSchema,
  createOAuthClientSchema,
  createPersonalAccessTokenSchema,
  createSSHKeySchema,
  enableTwoFactorSchema,
  updateAccountSchema,
  updateOAuthClientSchema,
  updateProfileSchema,
  updateSSHKeySchema
};
