// src/account/account.ts
import {
  updateAccountSchema,
  UpdateAccountSettingsSchema
} from "@bitnimbus/validation/lib/account.schema";

// src/constants.ts
var API_ROOT = "https://analytics-api-staging.bitnimbus.io";
var BETA_API_ROOT = API_ROOT + "beta";

// src/apiConfig.ts
var AppEnv = {
  LOCAL: {
    StacksvilleApi: "http://localhost:9000",
    DashboardUrl: "http://localhost:3001"
  },
  STAGING: {
    StacksvilleApi: "https://login-api-staging.bitnimbus.io",
    DashboardUrl: "https://login-staging.bitnimbus.io"
  },
  PROD: {
    StacksvilleApi: "https://login-api-prod.bitnimbus.io",
    DashboardUrl: "https://login.bitnimbus.io"
  }
};
var apiendpointConfig = {
  StacksvilleApi: "https://login-api-staging.bitnimbus.io",
  DashboardUrl: "https://login-staging.bitnimbus.io"
};
var configureApiEndpoints = (env) => {
  apiendpointConfig.StacksvilleApi = AppEnv[env].StacksvilleApi;
  apiendpointConfig.DashboardUrl = AppEnv[env].DashboardUrl;
};
var ApiConfig = apiendpointConfig;

// src/request.ts
import Axios, {
  AxiosHeaders
} from "axios";
var baseRequest = Axios.create({
  baseURL: "https://analytics-api-staging.bitnimbus.io"
  /*baseURL: 'http://localhost:9000/'*/
});
baseRequest.interceptors.request.use((config) => {
  const isRunningInNode = typeof process === "object";
  const newConfig = {
    ...config
  };
  return isRunningInNode ? newConfig : config;
});
var setToken = (token) => {
  return baseRequest.interceptors.request.use((config) => {
    const updatedConfig = {
      ...config
    };
    updatedConfig.headers.Authorization = `Bearer ${token}`;
    return updatedConfig;
  });
};
var set = (field, value) => (object) => {
  return !isEmpty(value) ? { ...object, [field]: value } : object;
};
var isEmpty = (v) => v === void 0 || v === null || v.length === 0 || typeof v === "object" && Object.keys(v).length === 0 && v.constructor === Object;
var setURL = (url) => set("url", url);
var setMethod = (method) => set("method", method);
var setParams = (params) => set("params", params);
var setData = (data, schema, postValidationTransform) => {
  if (!schema) {
    return set("data", data);
  }
  const updatedData = typeof postValidationTransform === "function" ? postValidationTransform(data) : data;
  try {
    schema.validateSync(data, { abortEarly: false });
    return set("data", updatedData);
  } catch (error) {
    return (object) => ({
      ...object,
      data: updatedData,
      validationErrors: converYupToValidationError(error)
    });
  }
};
var converYupToValidationError = (validationError) => {
  const { inner } = validationError;
  if (inner && inner.length > 0) {
    return inner.reduce((result, innerValidationError) => {
      const err = converYupToValidationError(innerValidationError);
      return Array.isArray(err) ? [...result, ...err] : [...result, err];
    }, []);
  }
  return [mapYupToApiError(validationError)];
};
var mapYupToApiError = ({
  message,
  path,
  params
}) => ({
  reason: message,
  ...path && { field: path },
  params
});
var setXFilter = (xFilter) => {
  return (object) => !isEmpty(xFilter) ? {
    ...object,
    headers: { ...object.headers, "X-Filter": JSON.stringify(xFilter) }
  } : object;
};
var reduceRequestConfig = (...fns) => fns.reduceRight((result, fn) => fn(result), {
  url: "https://analytics-api-staging.bitnimbus.io",
  headers: {}
});
var requestGenerator = (...fns) => {
  const config = reduceRequestConfig(...fns);
  if (config.validationErrors) {
    return Promise.reject(
      config.validationErrors
      // All failed requests, client or server errors, should be APIError[]
    );
  }
  return baseRequest(config).then((response) => response.data);
};
var request_default = requestGenerator;

// src/account/account.ts
import { FeedbackSchema } from "@bitnimbus/validation";
var getAccountInfo = () => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/account/`),
    setMethod("GET")
  );
};
var getNetworkUtilization = () => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/account/transfer`),
  setMethod("GET")
);
var updateAccountInfo = (data) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/account/billing`),
  setMethod("POST"),
  setData(data, updateAccountSchema)
);
var getAccountSettings = () => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/account/settings`),
  setMethod("GET")
);
var updateAccountSettings = (data) => request_default(
  setURL(`${API_ROOT}/account/settings`),
  setMethod("PUT"),
  setData(data, UpdateAccountSettingsSchema)
);
var cancelAccount = (data) => {
  return request_default(
    setURL(`${API_ROOT}/account/cancel`),
    setMethod("POST"),
    setData(data)
  );
};
var getAccountAgreements = {
  privacy_policy: true,
  eu_model: true,
  master_service_agreement: true
};
var signAgreement = (data) => {
  return request_default(
    setURL(`${BETA_API_ROOT}/account/agreements`),
    setMethod("POST"),
    setData(data)
  );
};
var deleteAccount = (feedback) => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/account`),
    setMethod("DELETE"),
    setData(feedback, FeedbackSchema)
  );
};
var getAccountUsage = () => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/account/usage`),
    setMethod("GET")
  );
};

// src/account/events.ts
var getEvents = (params = {}, filter = {}) => request_default(
  setURL(`${API_ROOT}/account/events`),
  setMethod("GET"),
  setXFilter(filter),
  setParams(params)
);
var getEvent = (eventId) => request_default(
  setURL(`${API_ROOT}/account/events/${encodeURIComponent(eventId)}`),
  setMethod("GET")
);
var markEventSeen = (eventId) => request_default(
  setURL(`${API_ROOT}/account/events/${encodeURIComponent(eventId)}/seen`),
  setMethod("POST")
);
var markEventRead = (eventId) => request_default(
  setURL(`${API_ROOT}/account/events/${encodeURIComponent(eventId)}/read`),
  setMethod("POST")
);
var getNotifications = (params, filter) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/account/notifications`),
  setMethod("GET"),
  setParams(params),
  setXFilter(filter)
);

// src/account/invoices.ts
var getInvoices = (params, filter) => request_default(
  setURL(`${API_ROOT}/account/invoices`),
  setMethod("GET"),
  setParams(params),
  setXFilter(filter)
);
var getInvoice = (invoiceId) => request_default(
  setURL(`${API_ROOT}/account/invoices/${encodeURIComponent(invoiceId)}`),
  setMethod("GET")
);
var getInvoiceItems = (invoiceId, params, filter) => request_default(
  setURL(
    `${API_ROOT}/account/invoices/${encodeURIComponent(invoiceId)}/items`
  ),
  setMethod("GET"),
  setParams(params),
  setXFilter(filter)
);

// src/account/payments.ts
import {
  CreditCardSchema,
  PaymentSchema,
  PaymentMethodSchema
} from "@bitnimbus/validation/lib/account.schema";
var getPayments = (params, filter) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/account/payments`),
  setMethod("GET"),
  setParams(params),
  setXFilter(filter)
);
var makePayment = (data) => {
  if (!data.cvv) {
    delete data.cvv;
  }
  return request_default(
    setURL(`${API_ROOT}/account/payments`),
    setMethod("POST"),
    setData(data, PaymentSchema)
  );
};
var saveCreditCard = (data) => {
  return request_default(
    setURL(`${API_ROOT}/account/credit-card`),
    setMethod("POST"),
    setData(data, CreditCardSchema)
  );
};
var stripePayment = () => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/billing/add-payment-method`),
    setMethod("POST")
  );
};
var getPaymentMethods = (params) => {
  return request_default(
    // setURL(`${STACKSVILLE_API_ROOT}/account/payment-methods`),
    setURL(`${ApiConfig.StacksvilleApi}/billing/payment-methods`),
    setMethod("GET")
  );
};
var getPaymentMethod = (id) => {
  return request_default(
    setURL(`${API_ROOT}/account/payment-method/${encodeURIComponent(id)}`),
    setMethod("GET")
  );
};
var getClientToken = () => {
  return request_default(
    setURL(`${API_ROOT}/account/client-token`),
    setMethod("GET")
  );
};
var addPaymentMethod = (data) => {
  return request_default(
    setURL(`${API_ROOT}/account/payment-methods`),
    setMethod("POST"),
    setData(data, PaymentMethodSchema)
  );
};
var makeDefaultPaymentMethod = (id) => {
  return request_default(
    setURL(
      `${API_ROOT}/account/payment-methods/${encodeURIComponent(
        id
      )}/make-default`
    ),
    setMethod("POST")
  );
};
var deletePaymentMethod = (id) => {
  return request_default(
    setURL(`${API_ROOT}/account/payment-methods/${encodeURIComponent(id)}`),
    setMethod("DELETE")
  );
};

// src/account/users.ts
import {
  CreateUserSchema,
  UpdateUserSchema
} from "@bitnimbus/validation/lib/account.schema";
var getUsers = (params, filters) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/account/users`),
  setMethod("GET")
);
var getUser = (username) => (
  /*Request<User>(
    setURL(`${API_ROOT}/account/users/${encodeURIComponent(username)}`),
    setMethod('GET')
  );*/
  request_default(
    setURL(`${ApiConfig.StacksvilleApi}/users/${encodeURIComponent(username)}`),
    setMethod("GET")
  )
);
var createUser = (data) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/users/add`),
  setMethod("POST"),
  setData(data, CreateUserSchema)
);
var updateUser = (userId, data) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/users/${encodeURIComponent(userId)}`),
  setMethod("PUT"),
  setData(data, UpdateUserSchema)
);
var deleteUser = (username) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/users/delete`),
  setData({ username }),
  setMethod("DELETE")
);
var getGrants = (username) => request_default(
  setURL(
    `${ApiConfig.StacksvilleApi}/account/users/${encodeURIComponent(
      username
    )}/grants`
  ),
  setMethod("GET")
);
var updateGrants = (username, data) => request_default(
  setURL(`${API_ROOT}/account/users/${encodeURIComponent(username)}/grants`),
  setMethod("PUT"),
  setData(data)
);

// src/account/oauth.ts
import {
  createOAuthClientSchema,
  updateOAuthClientSchema
} from "@bitnimbus/validation/lib/account.schema";
var getOAuthClients = (params, filter) => request_default(
  setURL(`${API_ROOT}/account/oauth-clients`),
  setMethod("GET"),
  setParams(params),
  setXFilter(filter)
);
var getOAuthClient = (clientId) => request_default(
  setURL(`${API_ROOT}/account/oauth-clients/${encodeURIComponent(clientId)}`),
  setMethod("GET")
);
var createOAuthClient = (data) => request_default(
  setURL(`${API_ROOT}/account/oauth-clients`),
  setMethod("POST"),
  setData(data, createOAuthClientSchema)
);
var resetOAuthClientSecret = (clientId) => request_default(
  setURL(
    `${API_ROOT}/account/oauth-clients/${encodeURIComponent(
      clientId
    )}/reset-secret`
  ),
  setMethod("POST")
);
var updateOAuthClient = (clientId, data) => request_default(
  setURL(`${API_ROOT}/account/oauth-clients/${encodeURIComponent(clientId)}`),
  setMethod("PUT"),
  setData(data, updateOAuthClientSchema)
);
var deleteOAuthClient = (clientId) => request_default(
  setURL(`${API_ROOT}/account/oauth-clients/${encodeURIComponent(clientId)}`),
  setMethod("DELETE")
);

// src/account/promos.ts
import { PromoCodeSchema } from "@bitnimbus/validation/lib/account.schema";
var addPromotion = (code) => request_default(
  setURL(`${API_ROOT}/account/promo-codes`),
  setMethod("POST"),
  setData({ promo_code: code }, PromoCodeSchema)
);

// src/account/types.ts
var UserPermission = /* @__PURE__ */ ((UserPermission2) => {
  UserPermission2["FULL"] = "full";
  UserPermission2["LIMITED"] = "limited";
  return UserPermission2;
})(UserPermission || {});
var UserType = /* @__PURE__ */ ((UserType2) => {
  UserType2["PRIMARY"] = "primary";
  UserType2["SECONDARY"] = "secondary";
  return UserType2;
})(UserType || {});
var AccountType = /* @__PURE__ */ ((AccountType2) => {
  AccountType2["FREE"] = "free";
  AccountType2["SELECT_1"] = "select_1";
  AccountType2["SELECT_2"] = "select_2";
  AccountType2["LOYAL_1"] = "loyal_1";
  return AccountType2;
})(AccountType || {});
var AccountStatus = /* @__PURE__ */ ((AccountStatus2) => {
  AccountStatus2["STAGED"] = "staged";
  AccountStatus2["ACTIVE"] = "active";
  return AccountStatus2;
})(AccountStatus || {});

// src/account/maintenance.ts
var getAccountMaintenance = (params, filter) => request_default(
  setURL(`${API_ROOT}/account/maintenance`),
  setMethod("GET"),
  setParams(params),
  setXFilter(filter)
);

// src/account/logins.ts
var getAccountLogins = (params, filter) => request_default(
  setURL(`${API_ROOT}/account/logins`),
  setMethod("GET"),
  setParams(params),
  setXFilter(filter)
);
var getAccountLogin = (loginId) => request_default(
  setURL(`${API_ROOT}/account/logins/${encodeURIComponent(loginId)}`),
  setMethod("GET")
);

// src/profile/twofactor.ts
import { enableTwoFactorSchema } from "@bitnimbus/validation/lib/twofactor.schema";
var getTFAToken = () => request_default(setMethod("POST"), setURL(`${API_ROOT}/profile/tfa-enable`));
var disableTwoFactor = () => request_default(setMethod("POST"), setURL(`${API_ROOT}/profile/tfa-disable`));
var confirmTwoFactor = (tfa_code) => request_default(
  setMethod("POST"),
  setURL(`${API_ROOT}/profile/tfa-enable-confirm`),
  setData({ tfa_code }, enableTwoFactorSchema)
);

// src/profile/sshkeys.ts
import {
  createSSHKeySchema,
  updateSSHKeySchema
} from "@bitnimbus/validation/lib/profile.schema";
var getSSHKeys = (params, filters) => request_default(
  setMethod("GET"),
  setParams(params),
  setXFilter(filters),
  setURL(`${API_ROOT}/profile/sshkeys`)
);
var getSSHKey = (keyId) => request_default(
  setMethod("GET"),
  setURL(`${API_ROOT}/profile/sshkeys/${encodeURIComponent(keyId)}`)
);
var createSSHKey = (data) => request_default(
  setMethod("POST"),
  setURL(`${API_ROOT}/profile/sshkeys`),
  setData(data, createSSHKeySchema)
);
var updateSSHKey = (keyId, data) => request_default(
  setMethod("PUT"),
  setURL(`${API_ROOT}/profile/sshkeys/${encodeURIComponent(keyId)}`),
  setData(data, updateSSHKeySchema)
);
var deleteSSHKey = (keyId) => request_default(
  setMethod("DELETE"),
  setURL(`${API_ROOT}/profile/sshkeys/${encodeURIComponent(keyId)}`)
);

// src/profile/profile.ts
import {
  SendCodeToPhoneNumberSchema,
  VerifyPhoneNumberCodeSchema,
  SecurityQuestionsSchema
} from "@bitnimbus/validation/lib/profile.schema";
import { updateProfileSchema } from "@bitnimbus/validation/lib/profile.schema";
var getProfile = () => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/users/profile`),
  setMethod("GET")
);
var updateProfile = (data) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/users/profile`),
  setMethod("PUT"),
  setData(data, updateProfileSchema)
);
var listGrants = (username) => request_default(
  setURL(
    `${ApiConfig.StacksvilleApi}/account/users/${encodeURIComponent(
      username
    )}/grants`
  )
);
var getMyGrants = (username) => request_default(
  setURL(
    `${ApiConfig.StacksvilleApi}/account/users/${encodeURIComponent(
      username
    )}/grants`
  ),
  setMethod("GET")
);
var getTrustedDevices = (params, filter) => request_default(
  setURL(`${API_ROOT}/profile/devices`),
  setMethod("GET"),
  setXFilter(filter),
  setParams(params)
);
var deleteTrustedDevice = (id) => request_default(
  setURL(`${API_ROOT}/profile/devices/${encodeURIComponent(id)}`),
  setMethod("DELETE")
);
var getUserPreferences = () => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/users/profile/preferences`)
  );
};
var updateUserPreferences = (payload) => {
  return request_default(
    setURL(`${API_ROOT}/users/profile/preferences`),
    setData(payload),
    setMethod("PUT")
  );
};
var getLogins = (params, filter) => {
  return request_default(
    setURL(`${API_ROOT}/profile/logins`),
    setMethod("GET"),
    setXFilter(filter),
    setParams(params)
  );
};
var getSecurityQuestions = () => {
  return request_default(
    setURL(`${API_ROOT}/profile/security-questions`),
    setMethod("GET")
  );
};
var updateSecurityQuestions = (payload) => {
  return request_default(
    setURL(`${API_ROOT}/profile/security-questions`),
    setMethod("POST"),
    setData(payload, SecurityQuestionsSchema)
  );
};
var smsOptOut = () => {
  return request_default(
    setURL(`${API_ROOT}/profile/phone-number`),
    setMethod("DELETE")
  );
};
var sendCodeToPhoneNumber = (data) => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/users/resend/otp`),
    setMethod("POST"),
    setData(data, SendCodeToPhoneNumberSchema)
  );
};
var verifyPhoneNumberCode = (data) => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/users/verify/otp`),
    setMethod("POST"),
    setData(data, VerifyPhoneNumberCodeSchema)
  );
};

// src/profile/appTokens.ts
var getAppTokens = (params, filters) => request_default(
  setMethod("GET"),
  setParams(params),
  setXFilter(filters),
  setURL(`${API_ROOT}/profile/apps`)
);
var getAppToken = (tokenId) => request_default(
  setMethod("GET"),
  setURL(`${API_ROOT}/profile/apps/${encodeURIComponent(tokenId)}`)
);
var deleteAppToken = (tokenId) => request_default(
  setURL(`${API_ROOT}/profile/apps/${encodeURIComponent(tokenId)}`),
  setMethod("DELETE")
);

// src/profile/accessTokens.ts
import { createPersonalAccessTokenSchema } from "@bitnimbus/validation/lib/profile.schema";
var getPersonalAccessTokens = (params, filters) => request_default(
  setMethod("GET"),
  setParams(params),
  setXFilter(filters),
  setURL(`${API_ROOT}/profile/tokens`)
);
var getPersonalAccessToken = (id) => request_default(
  setMethod("GET"),
  setURL(`${API_ROOT}/profile/tokens/${encodeURIComponent(id)}`)
);
var createPersonalAccessToken = (data) => request_default(
  setMethod("POST"),
  setURL(`${API_ROOT}/profile/tokens`),
  setData(data, createPersonalAccessTokenSchema)
);
var updatePersonalAccessToken = (tokenId, data) => request_default(
  setURL(`${API_ROOT}/profile/tokens/${encodeURIComponent(tokenId)}`),
  setMethod("PUT"),
  setData(data, createPersonalAccessTokenSchema)
);
var deletePersonalAccessToken = (tokenId) => request_default(
  setURL(`${API_ROOT}/profile/tokens/${encodeURIComponent(tokenId)}`),
  setMethod("DELETE")
);

// src/tags/tags.ts
var getTags = (params, filter) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/users/tags`),
  setMethod("GET")
  /*setParams(params),
  setXFilter(filter)*/
);
var createTag = (data) => request_default(setURL(`${API_ROOT}/tags`), setMethod("POST"), setData(data));
var deleteTag = (label) => request_default(
  setURL(`${API_ROOT}/tags/${encodeURIComponent(label)}`),
  setMethod("DELETE")
);

// src/types.ts
var ServiceType = /* @__PURE__ */ ((ServiceType2) => {
  ServiceType2["KAFKA"] = "kafka";
  ServiceType2["VectorDB"] = "vectordb";
  return ServiceType2;
})(ServiceType || {});
var StatusCode = /* @__PURE__ */ ((StatusCode2) => {
  StatusCode2["MOBILE_NOT_VERIFIED"] = "MOBILE_NOT_VERIFIED";
  return StatusCode2;
})(StatusCode || {});

// src/kafka/index.ts
import {
  DeleteKafkaSchema,
  UpdateKafkaSchema,
  CreateKafkaSchema
} from "@bitnimbus/validation";

// src/kafka/types.ts
var KafkaStatus = /* @__PURE__ */ ((KafkaStatus2) => {
  KafkaStatus2["IN_PROGRESS"] = "In Progress";
  KafkaStatus2["ACTIVE"] = "running";
  KafkaStatus2["FAILED"] = "failed";
  KafkaStatus2["UPDATING"] = "updating";
  return KafkaStatus2;
})(KafkaStatus || {});
var ClusterType = /* @__PURE__ */ ((ClusterType2) => {
  ClusterType2["COLOCATED"] = "colocated";
  return ClusterType2;
})(ClusterType || {});

// src/kafka/index.ts
var getAllKafka = (params, filter) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/kafka`),
  setMethod("GET"),
  setXFilter(filter),
  setParams(params)
);
var getKafkaById = (kafkaID) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/kafka/${encodeURIComponent(kafkaID)}`),
  setMethod("GET")
);
var getKafkaStatsByRange = (kafkaID, startTimestamp, endTimestamp) => request_default(
  setURL(
    `${ApiConfig.StacksvilleApi}/kafka/${encodeURIComponent(
      kafkaID
    )}/metrics?start=${encodeURIComponent(
      startTimestamp
    )}&end=${encodeURIComponent(endTimestamp)}`
  ),
  setMethod("GET")
);
var updateKafka = (kafkaId, values) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/kafka/${encodeURIComponent(kafkaId)}`),
  setMethod("PATCH"),
  setData(values, UpdateKafkaSchema)
);
var deleteKafka = (kafkaId, data) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/kafka/${encodeURIComponent(kafkaId)}`),
  setMethod("DELETE"),
  setData(data, DeleteKafkaSchema)
);
var createKafka = (data) => request_default(
  setURL(`${ApiConfig.StacksvilleApi}/kafka/create`),
  setMethod("POST"),
  setData(data, CreateKafkaSchema)
);
var getPlans = () => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/kafka/plans`)
  );
};

// src/regions/regions.ts
var getRegion = (regionID) => request_default(
  setURL(`${API_ROOT}/regions/${encodeURIComponent(regionID)}`),
  setMethod("GET")
);

// src/multicloud/types.ts
var PlanTier = /* @__PURE__ */ ((PlanTier2) => {
  PlanTier2["SMALL"] = "small";
  PlanTier2["MICRO"] = "micro";
  PlanTier2["MEDIUM"] = "medium";
  PlanTier2["LARGE"] = "large";
  return PlanTier2;
})(PlanTier || {});
var PlanGroup = /* @__PURE__ */ ((PlanGroup2) => {
  PlanGroup2["SMALL"] = "small";
  PlanGroup2["MEDIUM"] = "medium";
  PlanGroup2["LARGE"] = "large";
  return PlanGroup2;
})(PlanGroup || {});

// src/multicloud/index.ts
var getProviders = () => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/multicloud/providers`)
  );
};
var getRegions = () => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/multicloud/regions`)
  );
};

// src/vectordb/index.ts
import {
  CreateResourceSchema,
  UpdateResourceSchema
} from "@bitnimbus/validation";

// src/vectordb/types.ts
var VectorDBStatus = /* @__PURE__ */ ((VectorDBStatus2) => {
  VectorDBStatus2["ACTIVE"] = "running";
  VectorDBStatus2["IN_PROGRESS"] = "in progress";
  VectorDBStatus2["DELETING"] = "deleting";
  VectorDBStatus2["DELETED"] = "deleted";
  VectorDBStatus2["FAILED"] = "failed";
  VectorDBStatus2["UPDATING"] = "updating";
  return VectorDBStatus2;
})(VectorDBStatus || {});

// src/vectordb/index.ts
var createVectorDB = (payload) => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/vectordb/create`),
    setMethod("POST"),
    setData(payload, CreateResourceSchema)
  );
};
var getVectorDBPlans = () => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/vectordb/plans`),
    setMethod("GET")
  );
};
var listVectorDB = () => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/vectordb/`),
    setMethod("GET")
  );
};
var getVectorDB = (id) => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/vectordb/${id}`),
    setMethod("GET")
  );
};
var updateVectorDB = (id, payload) => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/vectordb/${id}`),
    setMethod("PATCH"),
    setData(payload, UpdateResourceSchema)
  );
};
var deleteVectorDB = (id) => {
  return request_default(
    setURL(`${ApiConfig.StacksvilleApi}/vectordb/${id}`),
    setMethod("DELETE")
  );
};
var getVectorDBMetrics = (vectordbId, startTimestamp, endTimestamp) => request_default(
  setURL(
    `${ApiConfig.StacksvilleApi}/vectordb/${encodeURIComponent(
      vectordbId
    )}/metrics?start=${encodeURIComponent(
      startTimestamp
    )}&end=${encodeURIComponent(endTimestamp)}`
  ),
  setMethod("GET")
);
export {
  AccountStatus,
  AccountType,
  ApiConfig,
  ClusterType,
  KafkaStatus,
  PlanGroup,
  PlanTier,
  ServiceType,
  StatusCode,
  UserPermission,
  UserType,
  VectorDBStatus,
  addPaymentMethod,
  addPromotion,
  baseRequest,
  cancelAccount,
  configureApiEndpoints,
  confirmTwoFactor,
  converYupToValidationError,
  createKafka,
  createOAuthClient,
  createPersonalAccessToken,
  createSSHKey,
  createTag,
  createUser,
  createVectorDB,
  deleteAccount,
  deleteAppToken,
  deleteKafka,
  deleteOAuthClient,
  deletePaymentMethod,
  deletePersonalAccessToken,
  deleteSSHKey,
  deleteTag,
  deleteTrustedDevice,
  deleteUser,
  deleteVectorDB,
  disableTwoFactor,
  getAccountAgreements,
  getAccountInfo,
  getAccountLogin,
  getAccountLogins,
  getAccountMaintenance,
  getAccountSettings,
  getAccountUsage,
  getAllKafka,
  getAppToken,
  getAppTokens,
  getClientToken,
  getEvent,
  getEvents,
  getGrants,
  getInvoice,
  getInvoiceItems,
  getInvoices,
  getKafkaById,
  getKafkaStatsByRange,
  getLogins,
  getMyGrants,
  getNetworkUtilization,
  getNotifications,
  getOAuthClient,
  getOAuthClients,
  getPaymentMethod,
  getPaymentMethods,
  getPayments,
  getPersonalAccessToken,
  getPersonalAccessTokens,
  getPlans,
  getProfile,
  getProviders,
  getRegion,
  getRegions,
  getSSHKey,
  getSSHKeys,
  getSecurityQuestions,
  getTFAToken,
  getTags,
  getTrustedDevices,
  getUser,
  getUserPreferences,
  getUsers,
  getVectorDB,
  getVectorDBMetrics,
  getVectorDBPlans,
  isEmpty,
  listGrants,
  listVectorDB,
  makeDefaultPaymentMethod,
  makePayment,
  markEventRead,
  markEventSeen,
  resetOAuthClientSecret,
  saveCreditCard,
  sendCodeToPhoneNumber,
  setToken,
  signAgreement,
  smsOptOut,
  stripePayment,
  updateAccountInfo,
  updateAccountSettings,
  updateGrants,
  updateKafka,
  updateOAuthClient,
  updatePersonalAccessToken,
  updateProfile,
  updateSSHKey,
  updateSecurityQuestions,
  updateUser,
  updateUserPreferences,
  updateVectorDB,
  verifyPhoneNumberCode
};
