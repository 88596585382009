import { APIError } from '@bitnimbus/api-v4';
import { Button, InputOTP } from '@bitnimbus/ui-components';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';

interface VerifyOtpProps {
  verifyOtpLoading?: boolean;
  verifyOtpSuccess?: boolean;
  verifyOtpError?: APIError[] | null;
  verifyOtp: (otp: string) => Promise<void>;
  resendOtp: () => Promise<void>;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  otpTitle: {
    fontSize: '1.2rem',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  verifyButton: {
    marginTop: theme.spacing(2),
  },
  resendText: {
    marginTop: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  errorText: {
    color: theme.palette.error.dark,
  },
}));

const VerifyOtp = (props: VerifyOtpProps) => {
  const classes = useStyles();

  const {
    verifyOtpError,
    verifyOtp,
    verifyOtpLoading,
    disabled,
    resendOtp,
  } = props;

  const [otp, setOtp] = useState<string>();
  const handleOtp = (value: string) => {
    setOtp(value);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography className={classes.otpTitle}>Enter OTP</Typography>
      <InputOTP
        length={4}
        onChange={handleOtp}
        disabled={disabled}
        value={otp}
        {...{
          ['data-testid']: 'verify-otp__otp-input',
        }}
      />
      {verifyOtpError && (
        <Typography className={classes.errorText}>
          {verifyOtpError[0].reason}
        </Typography>
      )}
      <Button
        className={classes.verifyButton}
        disabled={!otp || otp?.length !== 4}
        loading={verifyOtpLoading}
        onClick={() => verifyOtp(otp || '')}
        {...{
          ['data-testid']: 'verify-otp__verify-button',
        }}
      >
        Verify
      </Button>
      <Typography className={classes.resendText}>
        Didn't Receive code?{' '}
        <Button
          variant="link"
          style={{ padding: '0' }}
          onClick={resendOtp}
          disabled={disabled || verifyOtpLoading}
          {...{
            ['data-testid']: 'verify-otp__resend-button',
          }}
        >
          Resend Code
        </Button>
      </Typography>
    </Box>
  );
};

export default VerifyOtp;
